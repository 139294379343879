<template>
  <div>
    <b-card
      no-body
    >
      <b-card-header class="pb-50">
        <h5>
          {{ $t('spot.relatorio.titlePrimeiroCard') }}
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('spot.relatorio.labelDataIncial') }}</label>
            <flat-pickr
              v-model="dataBuscaFilter.inicio"
              class="form-control"
              :config="{ altInput: true, enableTime: false, altFormat: 'd/m/Y', locale: Portuguese}"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('spot.relatorio.labelDataFinal') }}</label>
            <flat-pickr
              v-model="dataBuscaFilter.fim"
              class="form-control"
              :config="{ altInput: true, enableTime: false, altFormat: 'd/m/Y', locale: Portuguese}"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('spot.relatorio.labelCoin') }}</label>
            <b-form-input
              id="coin"
              v-model="dataBuscaFilter.coin"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('spot.relatorio.sinal.labelStatus') }}</label>
            <v-select
              v-model="statusSinalFilter"
              :options="statusSinalOptions"
              class="w-100"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('spot.relatorio.sinal.labelTrader') }}</label>
            <v-select
              v-model="dataBuscaFilter.agente"
              :options="agenteList"
              :label="'nome'"
              :reduce="agente => agente.id"
              class="w-100"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <label style="visibility:hidden">{{ $t('spot.relatorio.labelAcao') }}</label>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="form-control"
              @click.prevent="buscarFiltro"
            >
              {{ $t('spot.relatorio.pesquisarBtn') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card
      no-body
    >
      <b-card-header class="pb-50">
        <h5>
          {{ $t('spot.relatorio.sinal.titleSegundoCard') }}
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg="6">
            <grafico-sinal
              :resumo-sinal="resumo"
              :series="series"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
    >
      <b-table
        ref="refListTable"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('spot.emptyText')"
        :sort-desc.sync="isSortDirDesc"
        striped
      >
        <template #cell(id)="data">
          <div class="text-nowrap">
            <span
              class="bullet bullet-sm mr-1"
              :class="`bullet-${getCorStatus(data.item.executado)}`"
            />
            <span>{{ data.value }}</span>
          </div>
        </template>

        <template #cell(paridade)="data">
          <div class="text-nowrap">
            <b-avatar
              size="sm"
              :src="getIcon(data.item.moedaTrade)"
            />
            <span class="mr-1">
              {{ data.item.moedaTrade }}<span class="font-weight-light">/{{ data.item.paridadeTrade }}</span>
            </span>
          </div>
        </template>

        <template #cell(valorCompra)="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(alvo1)="data">
          <template v-if="data.value">
            <div class="text-nowrap">
              {{ data.value }} <br>
              <b-badge variant="light-success">
                {{ getPercentDiff(data.item.valorCompra, data.value) }}%
              </b-badge>
              <feather-icon
                v-if="data.item.dataAlvo1!=null"
                class="text-success mr-1"
                size="16"
                icon="CheckIcon"
              />
            </div>
          </template>
        </template>

        <template #cell(alvo2)="data">
          <div class="text-nowrap">
            {{ data.value }}
            <template v-if="data.value">
              <br>
              <b-badge variant="light-success">
                {{ getPercentDiff(data.item.valorCompra, data.value) }}%
              </b-badge>
              <feather-icon
                v-if="data.item.dataAlvo2!=null"
                class="text-success mr-1"
                size="16"
                icon="CheckIcon"
              />
            </template>
          </div>
        </template>

        <template #cell(valorEncerrado)="data">
          <div
            v-if="data.item.status!='Cancelado'"
            class="text-nowrap"
          >
            {{ data.value }}
            <template v-if="data.value">
              <br>

              <template v-if="getPercentDiff(data.item.valorCompra, data.value)>=0">
                <b-badge variant="light-success">
                  {{ getPercentDiff(data.item.valorCompra, data.value) }}%
                </b-badge>
                <feather-icon
                  v-if="data.item.dataEncerrado!=null"
                  class="text-success mr-1"
                  size="16"
                  icon="CheckIcon"
                />
              </template>
              <template v-else>
                <b-badge variant="light-danger">
                  {{ getPercentDiff(data.item.valorCompra, data.value) }}%
                </b-badge>
                <feather-icon
                  v-if="data.item.dataEncerrado!=null"
                  class="text-danger mr-1"
                  size="16"
                  icon="CheckIcon"
                />
              </template>
            </template>
          </div>
        </template>

        <template #cell(stopLoss)="data">
          <template v-if="data.value">
            <div class="text-nowrap">
              {{ data.value }}
              <br>
              <b-badge variant="light-danger">
                {{ getPercentDiff(data.item.valorCompra, data.value) }}%
              </b-badge>
              <feather-icon
                v-if="data.item.dataStopLoss!=null"
                class="text-danger mr-1"
                size="16"
                icon="XIcon"
              />
            </div>
          </template>
        </template>

        <template #cell(dataCriacao)="data">
          {{ formatDateTimeSemAnoBR(data.value) }}
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('spot.textMuted', { from: paginationMeta.from, to: paginationMeta.to, of: paginationMeta.of }) }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>
  </div>
</template>

<script>

import {
  BCard, BTable, BAvatar, BPagination, BRow, BCol, BBadge, BCardHeader, BCardBody, BButton, VBTooltip, BFormInput,
} from 'bootstrap-vue'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import { formatDateTimeSemAnoBR, percentDiferenca, toIsoStringTimeZone } from '@/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import Ripple from 'vue-ripple-directive'
import sinalStoreModule from './sinalStoreModule'

const SINAL_SPOT_STORE_MODULE_NAME = 'sinal-relatorio-spot'

export default {

  components: {
    BCard,
    BTable,
    BAvatar,
    BPagination,
    BRow,
    BCol,
    BBadge,
    BCardHeader,
    BCardBody,
    flatPickr,
    BButton,
    vSelect,
    BFormInput,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  mixins: [utilsMixin],

  data() {
    return {
      Portuguese,
      formatDateTimeSemAnoBR,
      percentDiferenca,
      perPage: 10,
      totalCount: 0,
      currentPage: 1,
      sortBy: 'id,desc',
      isSortDirDesc: true,
      dataList: [],
      agenteList: [],
      dataBuscaFilter: {
        coin: null,
        agente: null,
        inicio: new Date(new Date().getFullYear(), new Date().getMonth(), '01'), // primeiro dia do mes atual
        fim: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
      },
      resumo: {
        totalPorcent: 0,
      },
      series: [],
      statusSinalFilter: { label: 'Todos', value: null },
    }
  },

  computed: {
    statusSinalOptions() {
      return [
        { label: this.$i18n.t('spot.relatorio.sinal.todos'), value: null },
        { label: this.$i18n.t('spot.relatorio.sinal.ativo'), value: '0' },
        { label: this.$i18n.t('spot.relatorio.sinal.fechado'), value: '1' },
        { label: this.$i18n.t('spot.relatorio.sinal.cancelado'), value: '2' },
      ]
    },
    tableColumns() {
      return [
        { key: 'id', label: this.$i18n.t('spot.relatorio.sinal.tableHeader.id') },
        { key: 'paridade', label: this.$i18n.t('spot.relatorio.sinal.tableHeader.paridade') },
        { key: 'status', label: this.$i18n.t('spot.relatorio.sinal.tableHeader.status') },
        { key: 'ordemTipoCompra', label: this.$i18n.t('spot.relatorio.sinal.tableHeader.ordemTipoCompra') },
        { key: 'valorCompra', label: this.$i18n.t('spot.relatorio.sinal.tableHeader.valorCompra') },
        { key: 'alvo1', label: this.$i18n.t('spot.relatorio.sinal.tableHeader.alvo1') },
        { key: 'alvo2', label: this.$i18n.t('spot.relatorio.sinal.tableHeader.alvo2') },
        { key: 'valorEncerrado', label: this.$i18n.t('spot.relatorio.sinal.tableHeader.valorEncerrado') },
        { key: 'stopLoss', label: this.$i18n.t('spot.relatorio.sinal.tableHeader.stopLoss') },
        { key: 'dataCriacao', label: this.$i18n.t('spot.relatorio.sinal.tableHeader.dataCriacao') },
        { key: 'agentePerfilTrade', label: this.$i18n.t('spot.relatorio.sinal.tableHeader.agentePerfilTrade') },
        { key: 'agente', label: this.$i18n.t('spot.relatorio.sinal.tableHeader.agente') },
      ]
    },
    paginationMeta() {
      const localItemsCount = this.$refs.refListTable ? this.$refs.refListTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalCount,
      }
    },
    getCorStatus() {
      return value => {
        if (value) {
          return 'success'
        }
        return 'danger'
      }
    },
  },

  watch: {
    currentPage() {
      this.fetchList()
      this.$refs.refListTable.refresh()
    },
  },

  created() {
    if (!store.hasModule(SINAL_SPOT_STORE_MODULE_NAME)) store.registerModule(SINAL_SPOT_STORE_MODULE_NAME, sinalStoreModule)
    this.getAgentes()
    this.fetchList()
  },

  beforeDestroy() {
    if (store.hasModule(SINAL_SPOT_STORE_MODULE_NAME)) store.unregisterModule(SINAL_SPOT_STORE_MODULE_NAME)
  },

  methods: {

    getAgentes() {
      store
        .dispatch('sinal-relatorio-spot/getAgentes')
        .then(response => {
          this.agenteList = response.data
        })
    },

    buscarFiltro() {
      if (this.isDataRangeDias(this.dataBuscaFilter.inicio, this.dataBuscaFilter.fim, 90)) {
        this.page = 1
        this.fetchList()
      }
    },

    fetchList() {
      let dataInicio = this.dataBuscaFilter.inicio
      if (typeof (dataInicio) === 'string' || dataInicio instanceof String) {
        dataInicio = toIsoStringTimeZone(new Date(dataInicio.split('-')[0], dataInicio.split('-')[1] - 1, dataInicio.split('-')[2]))
      }

      let dataFim = this.dataBuscaFilter.fim

      if (typeof (dataFim) === 'string' || dataFim instanceof String) {
        const dataFimTemp = new Date(dataFim.split('-')[0], dataFim.split('-')[1] - 1, dataFim.split('-')[2])
        dataFim = new Date(dataFimTemp.setDate(dataFimTemp.getDate() + 1)).toISOString()
      } else {
        dataFim = new Date(dataFim.setDate(dataFim.getDate() + 1))
      }

      store
        .dispatch('sinal-relatorio-spot/getSinaisResumo', {
          size: this.perPage,
          page: this.currentPage - 1,
          sortBy: this.sortBy,
          sortDesc: this.isSortDirDesc,
          dataInicio,
          dataFim,
          statusSinal: this.statusSinalFilter.value,
          coin: this.dataBuscaFilter.coin,
          agente: this.dataBuscaFilter.agente,
        })
        .then(response => {
          this.resumo = response.data
          this.series = [response.data.totalAtivoPercent, response.data.totalGanhoPercent, response.data.totalPerdaPercent]
        })

      store
        .dispatch('sinal-relatorio-spot/getSinais', {
          size: this.perPage,
          page: this.currentPage - 1,
          sortBy: this.sortBy,
          sortDesc: this.isSortDirDesc,
          dataInicio,
          dataFim,
          statusSinal: this.statusSinalFilter.value,
          coin: this.dataBuscaFilter.coin,
          agente: this.dataBuscaFilter.agente,
        })
        .then(response => {
          const { content, totalElements } = response.data
          this.dataList = content
          this.totalCount = totalElements
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao recuperar a lista.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
